import { projectRouters } from '@/routers/admins/project'
import { accountRouters } from '@/routers/admins/account'

export const adminRouters = {
  path: '/admin',
  name: 'admin',
  component: () => import('@/layouts/Adminlte'),
  redirect: 'admin/project',
  meta: {
    auth: true,
    roles: []
  },
  children: [
    projectRouters,
    accountRouters
  ]
}
