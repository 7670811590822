import { companyRouters } from './company'
import { projectRouters } from '@/routers/project'

export const secondaryPagesRouter = {
  path: '',
  name: 'secondary',
  component: () => import('@/layouts/Secondary'),
  redirect: '/',
  children: [
    {
      path: '/co',
      name: 'co',
      redirect: '/',
      component: () => import('@/views/occupancy/CertificateOfOccupancy'),
      children: [
        {
          path: '/co/:qr_code',
          name: 'certificate_occupancy_data',
          component: () => import('@/views/occupancy/OccupancyData')
        },
        {
          path: '/co/:qr_code/documents',
          name: 'certificate_occupancy_doc',
          component: () => import('@/views/occupancy/OccupancyDocument')
        }
      ]
    },
    {
      path: '/ccl/:qr_code',
      name: 'ccl',
      component: () => import('@/views/company/ConstructionCertifierLicense')
    },
    companyRouters,
    projectRouters
  ]
}
