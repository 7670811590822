import { mapState } from 'vuex'

export default {
  install (Vue, options) {
    Vue.mixin({
      computed: {
        ...mapState({
          roles: state => state.user.data ? state.user.data.roles : null,
          permissions: state => state.user.data ? state.user.data.permissions : null
        })
      },
      data () {
        return {
          locale: localStorage.getItem('locale') ? localStorage.getItem('locale') : 'en',
          administrators: [
            'administrator',
            'admin'
          ],
          baseUrl: process.env.VUE_APP_API
        }
      },
      methods: {
        onResponseError (error) {
          if (error.statusText) {
            this.$toastr('error', error.statusText, this.$t('string.error'))
          } else if (error.response) {
            if (error.response.status === 403) {
              this.$toastr('error', error.response.data.message, this.$t('string.error'))
            } else if (error.response.status === 422) {
              this.$toastr('error', error.response.data.message, this.$t('string.error'))
            } else if (error.response.status === 401) {
              this.$toastr('error', error.response.data.message, this.$t('string.error'))
              // localStorage.clear()
              // this.$router.push({name: 'login'})
              // this.$store.commit('user/clearUser')
            } else {
              try {
                if (error.response.data.message && error.response.data.message['message_' + this.$i18n.locale]) {
                  this.$toastr('error', error.response.data.message['message_' + this.$i18n.locale], this.$t('string.error'))
                } else {
                  this.$toastr('error', error.response.statusText, this.$t('string.error'))
                }
              } catch (e) {
                this.$toastr('error', this.$t('string.somethingWentWrong'), this.$t('string.error'))
              }
            }
          }
          if (error.response === undefined) {
            // localStorage.clear()
            // this.$router.push({name: 'login'})
            // this.$store.commit('user/clearUser')
          }
        },
        initBasicFunction () {
          if ($('#mainNav').offset() === undefined) {
            return 0
          }
          (function ($) {
            'use strict' // Start of use strict

            // Smooth scrolling using jQuery easing
            try {
              $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
                if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                  var target = $(this.hash)
                  target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
                  if (target.length) {
                    $('html, body').animate({
                      scrollTop: (target.offset().top - 72)
                    }, 1000, 'easeInOutExpo')
                    return false
                  }
                }
              })
            } catch (e) {

            }

            // Closes responsive menu when a scroll trigger link is clicked
            $('.js-scroll-trigger').click(function () {
              $('.navbar-collapse').collapse('hide')
            })

            // Activate scrollspy to add active class to navbar items on scroll
            $('body').scrollspy({
              target: '#mainNav',
              offset: 75
            })

            // Collapse Navbar
            var navbarCollapse = function () {
              if ($('#mainNav').offset().top > 100) {
                $('#mainNav').addClass('navbar-scrolled')
              } else {
                $('#mainNav').removeClass('navbar-scrolled')
              }
            }

            // Collapse now if page is not at top
            navbarCollapse()
            // Collapse the navbar when page is scrolled
            $(window).scroll(navbarCollapse)

            // Magnific popup calls
            $('#portfolio').magnificPopup({
              delegate: 'a',
              type: 'image',
              tLoading: 'Loading image #%curr%...',
              mainClass: 'mfp-img-mobile',
              gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1]
              },
              image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
              }
            })
          })(jQuery) // End of use strict
        },

        /**
         * function check user permission
         * @param permissions
         * @returns {boolean}
         */
        can (permissions) {
          if (!permissions || permissions.length === 0 || this.$store.getters['user/isAdmin']) {
            return true
          }

          if (!this.permissions) {
            return false
          }

          return this.permissions.some(permission => permissions.includes(permission))
        },
        /**
         * function check user has roles
         * @param roles
         * @returns {boolean}
         */
        hasRoles (roles) {
          return this.$store.getters['user/isAdmin'] || this.onlyHasRoles(roles)
        },
        /**
         * function check user has only roles
         * @param roles
         * @returns {boolean}
         */
        onlyHasRoles (roles) {
          if (!roles || roles.length === 0) {
            return true
          }
          if (!this.roles) {
            return false
          }
          return this.roles.some(role => roles.includes(role))
        },
        /**
         * function check user has role admin
         * @returns {boolean}
         */
        isAdmin () {
          if (!this.roles) {
            return false
          }
          return this.roles.some(role => this.administrators.includes(role))
        },
        getUserInfo () {
          const self = this
          this.$axios.post(this.$base_api + '/api/frontend/user/get-roles-and-permissions')
            .then((response) => {
              if (response.data.code === 1) {
                const result = response.data.data
                self.$store.dispatch('user/setUser', { user: result.user })
                // store roles
                self.$store.commit('user/setRoles', result.roles)

                // store permission
                self.$store.commit('user/setPermissions', result.permissions)
              }
            })
        }
      }
    })
  }
}
