import { companyDesignRouters } from './design'
import { companyLicenseRouters } from './license'

export const companyRouters = {
  path: '/cmp',
  name: 'cmp',
  redirect: '',
  component: () => import('@/views/company/certificates/Index'),
  children: [
    companyDesignRouters,
    companyLicenseRouters
  ]
}
