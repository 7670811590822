<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted () {
    this.initBasicFunction()
    if (localStorage.getItem(this.$token)) {
      this.getUserInfo()
      this.$store.dispatch('user/checkUserLogin', true)
    } else {
      this.$store.dispatch('user/checkUserLogin', false)
    }
  }
}
</script>
