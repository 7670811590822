export const state = {
  data: null,
  logged_in: false,
  administrators: [
    'administrator',
    'admin'
  ]
}

export const getters = {
  getUser: (state, getters, rootState) => {
    return state.data
  },
  isAdmin: (state) => {
    if (state.data) {
      return state.data.roles.some(role => state.administrators.includes(role))
    } else {
      return false
    }
  }
}

export const mutations = {
  setUser (state, data) {
    state.data = {
      id: data.user.id,
      email: data.user.email,
      full_name: data.user.full_name,
      name: data.user.name,
      first_name: data.user.first_name,
      last_name: data.user.last_name,
      created_at: data.user.created_at,
      updated_at: data.user.updated_at,
      email_verified_at: data.user.email_verified_at,
      avatar: data.user.avatar,
      gender: data.user.gender || null
    }
  },
  setRoles (state, roles) {
    if (state.data) {
      state.data.roles = roles
    } else {
      console.log('User data not yet defined')
    }
  },
  setPermissions (state, permissions) {
    if (state.data) {
      state.data.permissions = permissions
    } else {
      console.log('User data not yet defined')
    }
  },
  checkUserLogin (state, is_login) {
    state.logged_in = is_login
  },
  clearUser (state) {
    state.data = null
  }
}

export const actions = {
  setUser ({ commit, state }, data) {
    commit('setUser', data)
  },
  setRoles ({ commit, state }, data) {
    commit('setRoles', data)
  },
  setPermissions ({ commit, state }, data) {
    commit('setPermissions', data)
  },
  clearUser ({ commit, state }) {
    commit('clearUser')
  },
  checkUserLogin ({ commit, state }, data) {
    commit('checkUserLogin', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
