import './libraries/javascripts'
import './libraries/stylesheets'

import Vue from 'vue'
import axios from './axios'
import App from './App.vue'
import getRouter from './router'
import store from './store/index'
import BootstrapVue from 'bootstrap-vue'
import Meta from 'vue-meta'
import { i18n } from './locale'
import AsoraPlugin from './plugins/asora'
import VueToastr from '@deveodk/vue-toastr'
import VueSweetalert2 from 'vue-sweetalert2'
import Multiselect from 'vue-multiselect'
import VueQuillEditor from 'vue-quill-editor'
import Paginate from 'vuejs-paginate'
import Avatar from 'vue-avatar'
import Datepicker from 'vuejs-datepicker'
import loading from 'vuejs-loading-screen'
import * as VueGoogleMaps from 'vue2-google-maps'
import { VueMasonryPlugin } from 'vue-masonry'
import Viewer from 'v-viewer'
import VueQrcodeReader from 'vue-qrcode-reader'
import Lightbox from '@morioh/v-lightbox'
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$base_api = process.env.VUE_APP_API
Vue.prototype.$token = process.env.VUE_APP_TOKEN
Vue.prototype.$refresh_token = process.env.VUE_APP_REFRESH_TOKEN

Vue.use(VueLazyload)
Vue.use(BootstrapVue)
Vue.use(Meta)
Vue.use(AsoraPlugin)
Vue.use(VueQuillEditor)
Vue.use(loading, {
  bg: '#6f6f6fbd',
  size: 4,
  icon_color: '#3e3e3e'
})

Vue.use(VueToastr, {
  defaultPosition: 'toast-top-right',
  defaultType: 'info',
  defaultTimeout: 1000
})

Vue.use(VueSweetalert2, {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674'
})
Vue.use(VueMasonryPlugin)
Vue.use(Viewer)
Vue.use(VueQrcodeReader)
Vue.use(Lightbox)

Vue.component('multiselect', Multiselect)
Vue.component('paginate', Paginate)
Vue.component('avatar', Avatar)
Vue.component('date-picker', Datepicker)

const moment = require('moment')
require('@thyrith/momentkh')(moment)
require('moment/locale/es')

Vue.use(require('vue-moment'), {
  moment
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCko7Leh71etR118fhDLyEVuaw4deIrOuw',
    libraries: 'places'
  }
})

const vm = new Vue({
  router: getRouter(store),
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

export default vm
