export const accountRouters = {
  path: 'account',
  name: 'account',
  redirect: 'account/profile',
  meta: {
    auth: true,
    roles: [],
    groups: ['account']
  },
  component: () => import('@/views/admin/pages/account/Layout'),
  children: [
    {
      path: 'profile',
      name: 'profile',
      meta: {
        auth: true,
        roles: [],
        groups: ['account'],
        title: 'page.title.account',
        description: 'page.description.account',
        breadcrumbs: [
          {
            name_en: 'Account',
            name_km: 'គណនីរបស់អ្នក'
          },
          {
            name_en: 'Profile',
            name_km: 'គណនីរបស់អ្នក'
          }
        ]
      },
      component: () => import('@/views/admin/pages/account/Index')
    }
  ]
}
