export const projectRouters = {
  path: 'project',
  name: 'project',
  redirect: 'project/search',
  meta: {
    auth: true,
    roles: [],
    groups: ['project']
  },
  component: () => import('@/views/admin/pages/project/Index'),
  children: [
    {
      path: 'search',
      name: 'search',
      meta: {
        auth: true,
        roles: [],
        groups: ['project'],
        title: 'page.title.search_project',
        description: 'page.description.search_project',
        breadcrumbs: [
          {
            name_en: 'Project',
            name_km: 'គម្រោង'
          },
          {
            name_en: 'Search',
            name_km: 'ស្វែររក'
          }
        ]
      },
      component: () => import('@/views/admin/pages/project/Search')
    }
  ]
}
