import Vue from 'vue'
import Router from 'vue-router'
import axios from '@/axios'
import { primaryRouter } from '@/routers/primaryPagesRouter'
import { secondaryPagesRouter } from '@/routers/secondaryPagesRouter'
import { adminRouters } from '@/routers/admin'
import { errorsRouter } from '@/routers/errors'
import { unauthorizedRouter } from '@/routers/unauthorized'
import { loginRouter } from '@/routers/authPagesRouter'

Vue.use(Router)

const getRouter = (store) => {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      primaryRouter,
      secondaryPagesRouter,
      adminRouters,
      loginRouter,
      unauthorizedRouter,
      errorsRouter
    ]
  })

  const clearUserData = (next) => {
    localStorage.clear()
    store.dispatch('user/clearUser')
    next({ name: 'login' })
  }

  const handleUserData = (to, from, next, callback) => {
    /**
     * If no user data, we need to fetch it first
     */
    if (!store.state.user.data) {
      const _token = localStorage.getItem(process.env.VUE_APP_TOKEN)
      /**
       * Token must be defined in localstorage in order to fetch user data,
       * Otherwise, it will redirect to login page
       */
      if (_token) {
        axios.post(process.env.VUE_APP_API + '/api/frontend/user/get-roles-and-permissions')
          .then((response) => {
            if (response.data && response.data.data) {
              const result = response.data.data
              store.dispatch('user/setUser', { user: result.user })
              // store roles
              store.dispatch('user/setRoles', result.roles)
              // store permission
              store.dispatch('user/setPermissions', result.permissions)
              callback()
            } else {
              clearUserData(next)
            }
          })
          .catch(() => {
            clearUserData(next)
          })
      } else {
        clearUserData(next)
      }
    } else {
      callback()
    }
  }

  router.beforeEach((to, from, next) => {
    /**
     * User should go to login page when already login
     */
    if (to.name === 'login' && localStorage.getItem(process.env.VUE_APP_TOKEN)) {
      next({ name: 'admin', replace: true })
      return
    }

    /**
     * Need to defined noAuth meta on unprotected route
     * Any unprotected route, no need to check, just proceed
     */
    if (!to.meta.auth) {
      next()
      return
    }

    handleUserData(to, from, next, () => {
      const userRoles = store.state.user.data.roles || []
      const requiredRoles = to.meta.roles || []
      const rolesQualified = requiredRoles.length > 0 ? store.getters['user/isAdmin'] || userRoles.some(role => requiredRoles.includes(role)) : true
      if (rolesQualified) {
        next()
      } else {
        next({ name: 'unauthorized' })
      }
    })
  })
  return router
}

export default getRouter
