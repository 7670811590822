export const projectRouters = {
  path: '/project',
  component: () => import('@/views/projects/Index'),
  children: [
    {
      path: 'info/public/view/ln/:token',
      name: 'public-view-project',
      component: () => import('@/views/projects/ShowForPublic')
    }
  ]
}
