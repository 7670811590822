export const primaryRouter = {
  path: '',
  name: 'primary',
  component: () => import('@/layouts/Primary'),
  redirect: '/',
  children: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home')
    }
  ]
}
